import * as React from 'react';
import { graphql } from 'gatsby';
import * as styles from '../css/template.module.css';
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import SEO from '../components/SEO';

import Layout from '../components/Layout';

const AboutPage = ({ data }) => {
  const { aboutPageTitle, aboutPageSubTitle, body } = data.directus.aboutPage;
  return (
    <Layout>
      <SEO title="About" />

      <StyledHero
        img={
          data.directus.aboutPage.backgroundImage.imageFile.childImageSharp
            .fluid
        }
      >
        <Banner title={aboutPageTitle} info={aboutPageSubTitle} />
      </StyledHero>
      <section className={styles.template}>
        <div className={styles.center}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    directus {
      aboutPage {
        aboutPageTitle
        aboutPageSubTitle
        backgroundImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body
      }
    }
  }
`;

export default AboutPage;
